import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { internalRootPaths } from 'components/App';
import { useGlobalState } from 'context/GlobalState';
import {
  ArrowsLeftRightIcon,
  Box,
  BuildingsIcon,
  ListItemIcon,
  ListItemText,
  UsersIcon,
} from 'elements';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import { useCanUser } from 'services/rbac';
import NotificationsMenu from './NotificationsMenu';
import PlatformAdminMenu from './PlatformAdminMenu';
import ReceiptInboxButton from './ReceiptInboxButton';
import UserMenu from './UserMenu';
import { MenuSection, StyledListItemButton, StyledMainHeader } from './style';
import withLightNavigationModeTheme from './withLightNavigationModeTheme';

const InternalAdminMainHeader = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { organization, featureModules },
  } = useGlobalState();
  const { isOrgInOnboarding } = useIsOrgInOnboarding();

  return (
    <StyledMainHeader>
      <Box display="flex" alignItems="center" flexGrow={1}>
        {canUser('organizations-page:visit') && (
          <StyledListItemButton
            component={NavLink}
            to={internalRootPaths.organizations}
            exact
          >
            <ListItemIcon>
              <BuildingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('int.headerNav.organizations')} />
          </StyledListItemButton>
        )}
        {canUser('users-page:visit') && (
          <StyledListItemButton
            component={NavLink}
            to={internalRootPaths.users}
          >
            <ListItemIcon>
              <UsersIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('int.headerNav.users')} />
          </StyledListItemButton>
        )}
        {canUser('global-transactions-page:visit') && (
          <StyledListItemButton
            component={NavLink}
            to={internalRootPaths.globalTransactions}
          >
            <ListItemIcon>
              <ArrowsLeftRightIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('int.headerNav.transactions')} />
          </StyledListItemButton>
        )}
        <PlatformAdminMenu />
      </Box>
      <MenuSection>
        {!!organization &&
          canUser('receipt-inbox:view') &&
          !isOrgInOnboarding &&
          featureModules.RECEIPT_MANAGEMENT && <ReceiptInboxButton />}
        <NotificationsMenu />
        <UserMenu />
      </MenuSection>
    </StyledMainHeader>
  );
};

export default withLightNavigationModeTheme(InternalAdminMainHeader);

import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  OnboardingGroup,
  OnboardingSection,
  OnboardingSections,
  OnboardingSectionType,
  OnboardingTaskAnnualRevenue,
  OnboardingTaskBalanceSheetTotal,
  OnboardingTaskCompanyType,
  OnboardingTaskFoundationDate,
  OnboardingTaskIdentification,
  OnboardingTaskOrgName,
  OnboardingTaskPepFatca,
  OnboardingTaskQA,
  OnboardingTaskRegAddress,
  OnboardingTaskRegNumber,
  OnboardingTasks,
  OnboardingTaskStaffHeadcount,
  OnboardingTaskTaxId,
  OnboardingTaskTerms,
  OnboardingTaskVatId,
  UpdateCompanyTypePayload,
  UpdateIdentificationPayload,
  UpdateOrgAnnualRevenuePayload,
  UpdateOrgBalanceSheetTotalPayload,
  UpdateOrgBasePayload,
  UpdateOrgFoundationDatePayload,
  UpdateOrgNamePayload,
  UpdateOrgRegAddressPayload,
  UpdateOrgRegNumberPayload,
  UpdateOrgStaffHeadcountPayload,
  UpdateOrgVatIdPayload,
  UpdatePepFatcaPayload,
  UpdateTaxIdPayload,
} from 'services/constants';
import { onboardingKeys } from './queryKeys';
import useRequest from './useRequest';

export const useOnboardingQuery = (request: ReturnType<typeof useRequest>) => {
  const useGetOnboardingSections = (
    organizationId: string,
    queryOptions?: UseQueryOptions<OnboardingSections>
  ) =>
    useQuery<OnboardingSections>({
      queryKey: onboardingKeys.sections(organizationId),
      queryFn: () =>
        request<OnboardingSections>(`/onboarding/${organizationId}/sections`),
      staleTime: 1000 * 60 * 5,
      ...queryOptions,
    });

  const useGetOnboardingTasks = (
    organizationId: string,
    sectionType: OnboardingSectionType,
    queryOptions?: UseQueryOptions<OnboardingTasks>
  ) =>
    useQuery<OnboardingTasks>({
      queryKey: onboardingKeys.tasks(organizationId, sectionType),
      queryFn: () =>
        request<OnboardingTasks>(
          `/onboarding/${organizationId}/sections/tasks`,
          { params: { sectionType } }
        ),
      staleTime: 1000 * 60 * 5,
      ...queryOptions,
    });

  const useGetOnboardingIdentificationTask = (
    organizationId: string,
    taskId: string,
    queryOptions?: UseQueryOptions<OnboardingTaskIdentification>
  ) =>
    useQuery<OnboardingTaskIdentification>({
      queryKey: onboardingKeys.task(organizationId, taskId),
      queryFn: () =>
        request(`/onboarding/${organizationId}/tasks/identification/${taskId}`),
      ...queryOptions,
    });

  const useGetOnboardingTermsTask = (
    organizationId: string,
    taskId: string,
    queryOptions?: UseQueryOptions<OnboardingTaskTerms>
  ) =>
    useQuery<OnboardingTaskTerms>({
      queryKey: onboardingKeys.task(organizationId, taskId),
      queryFn: () =>
        request(`/onboarding/${organizationId}/tasks/terms/${taskId}`),
      ...queryOptions,
    });

  const useGetOnboardingGroupTasks = (
    organizationId: string,
    taskGroupId: string,
    queryOptions?: UseQueryOptions<OnboardingTasks>
  ) =>
    useQuery<OnboardingTasks>({
      queryKey: onboardingKeys.groupTasks(organizationId, taskGroupId),
      queryFn: () =>
        request<OnboardingTasks>(
          `/onboarding/${organizationId}/task-group/${taskGroupId}/tasks`
        ),
      staleTime: 1000 * 60 * 5,
      ...queryOptions,
    });

  const useUpdateOrgName = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskOrgName,
      unknown,
      UpdateOrgNamePayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/organization-name/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateRegisteredAddress = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskRegAddress,
      unknown,
      UpdateOrgRegAddressPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/registered-address/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateRegisteredNumber = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskRegNumber,
      unknown,
      UpdateOrgRegNumberPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/registered-number/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateFoundationDate = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskFoundationDate,
      unknown,
      UpdateOrgFoundationDatePayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/foundation-date/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateVatId = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskVatId,
      unknown,
      UpdateOrgVatIdPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(`/onboarding/${organizationId}/tasks/vat-id/${taskId}`, {
          method: 'put',
          data,
        }),
      ...mutationOptions,
    });

  const useUpdateAnnualRevenue = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskAnnualRevenue,
      unknown,
      UpdateOrgAnnualRevenuePayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/annual-revenue/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateBalanceSheetTotal = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskBalanceSheetTotal,
      unknown,
      UpdateOrgBalanceSheetTotalPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/balance-sheet-total/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateStaffHeadcount = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskStaffHeadcount,
      unknown,
      UpdateOrgStaffHeadcountPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/staff-headcount/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateCompanyType = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskCompanyType,
      unknown,
      UpdateCompanyTypePayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(`/onboarding/${organizationId}/tasks/company-type/${taskId}`, {
          method: 'put',
          data,
        }),
      ...mutationOptions,
    });

  const useUpdatePepFatca = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskPepFatca,
      unknown,
      UpdatePepFatcaPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(`/onboarding/${organizationId}/tasks/pep-fatca/${taskId}`, {
          method: 'put',
          data,
        }),
      ...mutationOptions,
    });

  const useUpdateTaxId = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskTaxId,
      unknown,
      UpdateTaxIdPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(`/onboarding/${organizationId}/tasks/tax-id/${taskId}`, {
          method: 'put',
          data,
        }),
      ...mutationOptions,
    });

  const useUpdateIdentification = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskIdentification,
      unknown,
      UpdateIdentificationPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/identification/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateQA = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskQA,
      unknown,
      UpdateOrgBasePayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId }) =>
        request(`/onboarding/${organizationId}/tasks/question/${taskId}`, {
          method: 'post',
        }),
      ...mutationOptions,
    });

  const useUpdateTerms = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskTerms,
      unknown,
      UpdateOrgBasePayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId }) =>
        request(`/onboarding/${organizationId}/tasks/terms/${taskId}`, {
          method: 'post',
        }),
      ...mutationOptions,
    });

  const useSubmitOnboardingSection = (
    mutationOptions?: UseMutationOptions<
      OnboardingSection,
      unknown,
      { organizationId: string; type: OnboardingSectionType }
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, type }) =>
        request(`/onboarding/${organizationId}/sections/submit`, {
          method: 'post',
          data: { type },
        }),
      ...mutationOptions,
    });

  const useSubmitOnboardingTaskGroup = (
    mutationOptions?: UseMutationOptions<
      OnboardingGroup,
      unknown,
      { organizationId: string; taskGroupId: string }
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskGroupId }) =>
        request(
          `/onboarding/${organizationId}/task-group/${taskGroupId}/submit`,
          { method: 'post' }
        ),
      ...mutationOptions,
    });

  return {
    useGetOnboardingSections,
    useGetOnboardingTasks,
    useGetOnboardingIdentificationTask,
    useGetOnboardingTermsTask,
    useGetOnboardingGroupTasks,
    useUpdateOrgName,
    useUpdateRegisteredAddress,
    useUpdateRegisteredNumber,
    useUpdateFoundationDate,
    useUpdateVatId,
    useUpdateAnnualRevenue,
    useUpdateBalanceSheetTotal,
    useUpdateStaffHeadcount,
    useUpdateCompanyType,
    useUpdatePepFatca,
    useUpdateTaxId,
    useUpdateIdentification,
    useUpdateQA,
    useUpdateTerms,
    useSubmitOnboardingSection,
    useSubmitOnboardingTaskGroup,
  };
};

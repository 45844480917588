import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import {
  adminPaths,
  orgOnboardingPaths,
  partnerPaths,
} from 'components/App/ExternalApp/paths';
import AppLogo from 'components/AppLogo';
import { useGlobalState } from 'context/GlobalState';
import PartnerLogo from 'domains/partner/components/PartnerLogo';
import {
  PARTNER_AUTH_SESSION_KEY,
  PARTNER_AUTH_SESSION_VALUE,
} from 'domains/partner/constants';
import { LogoWrapper } from 'domains/partner/pages/style';
import { isPartnerBasedSource } from 'domains/partner/utils';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Link,
  LoaderWithOverlay,
  PlugsConnectedIcon,
  PlugsIcon,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer } from 'layout';
import { PartnerAuthStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const statusType = {
  error: 'error',
  success: 'success',
};

const PartnerAuthStatusPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const { isOrgInOnboardingNew } = useIsOrgInOnboarding();
  const {
    dispatch,
    state: { organization, partners, featureModules },
  } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);
  // memoize received information
  const { status, partnerId } = useMemo(
    () =>
      Object.fromEntries(
        new URLSearchParams(history.location.search).entries()
      ),
    []
  );

  const isEmbeddedPartnerPageUsed = !featureModules.INTEGRATIONS_PAGE;
  const orgHasNoSourcePartner = !isPartnerBasedSource(organization!.partnerId);

  const basicRedirectionUrl =
    orgHasNoSourcePartner || !isEmbeddedPartnerPageUsed
      ? adminPaths.settingsIntegrations
      : adminPaths.partner;
  const appRedirectionUrl = isOrgInOnboardingNew
    ? orgOnboardingPaths.orgOnboarding
    : basicRedirectionUrl;

  const partner = partnerId
    ? partners?.find((item) => item.partnerId === partnerId)
    : undefined;

  const resetPartnerStatus = async () => {
    setIsLoading(true);
    try {
      const updatedPartner = await api.changePartnerStatus(
        partner!.partnerId,
        organization!.id,
        { status: PartnerAuthStatus.inactive }
      );
      // update organization partner (source) - only if source exists
      if (organization!.partnerName === partner!.name) {
        dispatch({
          type: 'SET_PARTNER_DATA',
          payload: {
            partnerOrgAuthDetails: updatedPartner,
          },
        });
      }
      if (!mounted.current) return;
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      history.push(appRedirectionUrl);
      logError(error);
    }
  };

  useEffect(() => {
    // replace query params, so that on reload user will be
    // automatically redirected
    history.replace({
      search: '',
    });

    if (
      partner &&
      status === 'error' &&
      sessionStorage.getItem(PARTNER_AUTH_SESSION_KEY) ===
        PARTNER_AUTH_SESSION_VALUE
    )
      resetPartnerStatus();

    return () => sessionStorage.removeItem(PARTNER_AUTH_SESSION_KEY);
  }, [partner, status]);

  if (
    sessionStorage.getItem(PARTNER_AUTH_SESSION_KEY) !==
      PARTNER_AUTH_SESSION_VALUE ||
    !partner ||
    (status !== 'error' && status !== 'success')
  )
    return <Redirect to={appRedirectionUrl} />;

  if (isLoading) return <LoaderWithOverlay loading />;

  if (status === statusType.success)
    return (
      <ContentContainer mx="auto">
        <Alert severity="success">
          <AlertTitle>
            {t('partnerIntegrationStatusPage.successMsg', {
              name: partner.name,
            })}
          </AlertTitle>
          <Trans
            i18nKey="partnerIntegrationStatusPage.successDescription"
            components={{
              linkTo: <Link href={appRedirectionUrl} color="inherit" />,
            }}
            values={{
              name: partner.name,
            }}
          />
        </Alert>

        <Box display="flex" alignItems="center" justifyContent="center" mt={8}>
          <LogoWrapper elevation={0} variant="tinted">
            <AppLogo />
          </LogoWrapper>

          <PlugsConnectedIcon color="success" fontSize="large" sx={{ mx: 6 }} />

          <LogoWrapper elevation={0} variant="tinted">
            <PartnerLogo partnerId={partner.partnerId} />
          </LogoWrapper>
        </Box>

        <Box textAlign="center" mt={6}>
          <Button size="large" onClick={() => history.push('/')}>
            {t('partnerIntegrationStatusPage.goBackButton')}
          </Button>
        </Box>
      </ContentContainer>
    );

  return (
    <ContentContainer mx="auto">
      <Alert severity="error">
        <AlertTitle>
          {t('partnerIntegrationStatusPage.errorMsg', { name: partner.name })}
        </AlertTitle>
        {t('partnerIntegrationStatusPage.errorDescription')}
      </Alert>

      <Box display="flex" alignItems="center" justifyContent="center" mt={8}>
        <LogoWrapper elevation={0} variant="tinted">
          <AppLogo />
        </LogoWrapper>

        <PlugsIcon color="error" fontSize="large" sx={{ mx: 6 }} />

        <LogoWrapper elevation={0} variant="tinted">
          <PartnerLogo partnerId={partner.partnerId} />
        </LogoWrapper>
      </Box>

      <Box textAlign="center" mt={6}>
        <Button
          size="large"
          variant="outlined"
          onClick={() => history.push(appRedirectionUrl)}
        >
          {t('partnerIntegrationStatusPage.laterButton')}
        </Button>
        <Button
          sx={{ ml: 2 }}
          size="large"
          onClick={() => history.push(partnerPaths.partners)}
        >
          {t('partnerIntegrationStatusPage.tryAgainButton')}
        </Button>
      </Box>
    </ContentContainer>
  );
};

export default withPageConfig(PartnerAuthStatusPage, {
  permission: 'partners:change',
});

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useGlobalState, useManagedTeams } from 'context/GlobalState';
import {
  CardSection,
  DeclinedTransactionInfoSection,
  ExportTransaction,
  ReuploadReceiptsError,
  ReviewTransaction,
  TransactionDetailsHeader,
  TransactionDisputeSection,
  TransactionReceipts,
  TransactionReviewComment,
} from 'domains/transaction/components';
import { WarningContainer } from 'domains/transaction/components/TransactionDetails/style';
import { useUpdateTransactionsCounters } from 'domains/transaction/hooks';
import { isTransactionReadonly } from 'domains/transaction/utils';
import {
  ActionBox,
  ActionBoxActions,
  ActionBoxTitle,
  Box,
  Button,
  LoaderWithOverlay,
  PencilSimpleIcon,
  PlusIcon,
  Tooltip,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { DetailsDrawerContent, DetailsDrawerHeader } from 'layout';
import {
  AccountingTransaction,
  MerchantCategory,
  MinimalTeam,
  Receipt,
  ReceiptAutomatchStatus,
  ReviewFlagReason,
  Supplier,
  SUPPORT_EMAIL,
  Transaction,
  TransactionExportStatus,
  TransactionReviewStatus,
  TransactionSimpleType,
  TransactionStatus,
  UpdateAccountingTransactionsPayload,
  UpdateAccTxnCustomFieldPayload,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, getPath } from 'services/utils';
import Account from './Account';
import AccountingTransactions from './AccountingTransactions';
import AdditionalInfoSection from './AdditionalInfoSection';
import Comment from './Comment';
import Merchant from './Merchant';
import SupplierSelect from './SupplierSelect';
import TeamsSelect from './TeamsSelect';

interface State {
  isUpdating: boolean;
  isCommentEditing: boolean;
  isReceiptNumberEditing: boolean;
  isReceiptDateEditing: boolean;
  isSplitting: boolean;
}

interface Props {
  transaction: Transaction;
  accountingTransactions: AccountingTransaction[];
  receipts: Receipt[];
  receiptDateStatus: ReceiptAutomatchStatus;
  receiptNumberStatus: ReceiptAutomatchStatus;
  teams: MinimalTeam[];
  isAdminApp: boolean;
  isExportPage: boolean;
  onUpdate: (
    transaction: Transaction,
    accountingTransactions: AccountingTransaction[]
  ) => void;
  onReceiptUpdate: () => void;
  onReviewUpdated?: (reviewStatus: TransactionReviewStatus) => void;
  onRefresh: () => void;
  refetchReceipts: () => void;
  refetchAccountingTransactions: () => void;
  isReceiptPreviewDialogOpen: boolean;
  onReceiptPreviewDialogOpen: (receiptId?: string) => void;
  onChangeExportStatus?: (
    id: string,
    newExportStatus: TransactionExportStatus
  ) => void;
}

const TransactionDetailsContent = ({
  transaction,
  accountingTransactions,
  receipts,
  receiptDateStatus,
  receiptNumberStatus,
  teams,
  isAdminApp,
  isExportPage,
  onUpdate,
  onReceiptUpdate,
  onReviewUpdated,
  onRefresh,
  refetchReceipts,
  refetchAccountingTransactions,
  isReceiptPreviewDialogOpen,
  onReceiptPreviewDialogOpen,
  onChangeExportStatus,
}: Props) => {
  const { organizationMerchantsPageEnabled, suppliersEnabled } = useFlags();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const canUser = useCanUser();
  const {
    state: {
      accountingSettings,
      featureModules,
      featureModulesWithData,
      organization,
    },
  } = useGlobalState();
  const mounted = useMounted();
  const routeMatch = useRouteMatch();
  const updateTransactionsCounters = useUpdateTransactionsCounters();
  const managedTeams = useManagedTeams();
  const managedTeamsIds = useMemo(() => managedTeams.map((item) => item.id), [
    managedTeams,
  ]);
  const [state, setState] = useState<State>({
    isUpdating: false,
    isCommentEditing: false,
    isReceiptDateEditing: false,
    isReceiptNumberEditing: false,
    isSplitting: false,
  });

  const transactionId = transaction.transactionId;

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      isUpdating: false,
      isCommentEditing: false,
      isReceiptDateEditing: false,
      isReceiptNumberEditing: false,
      isSplitting: false,
    }));
  }, [transaction.transactionId]);

  const isNeedsReviewVisible = () => {
    return (
      featureModules.MANAGER_TX_REVIEWS &&
      transaction.reviewStatus === TransactionReviewStatus.needsReview &&
      !isExportPage
    );
  };

  const isNeedsReviewOnExportPageVisible = () => {
    return (
      featureModules.MANAGER_TX_REVIEWS &&
      featureModulesWithData.MANAGER_TX_REVIEWS.settings
        .REVIEW_FOR_EXPORT_REQUIRED &&
      (routeMatch?.path === getPath('exportTransactionDetails') ||
        routeMatch?.path === getPath('exportQueueTransactionDetails')) &&
      transaction.reviewStatus === TransactionReviewStatus.needsReview
    );
  };

  const updateAccountingTransactions = async (
    accountingTransactions: UpdateAccountingTransactionsPayload
  ) => {
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      const data = await api.updateAccountingTransactions(
        transactionId,
        organization!.id,
        accountingTransactions
      );
      if (!mounted.current) return;
      onUpdate(
        {
          ...transaction,
          category: data.accountingTransactions[0].category,
          hasMultipleAccountingTransactions:
            data.accountingTransactions.length > 1,
        },
        data.accountingTransactions
      );

      setState((prevState) => ({
        ...prevState,
        isUpdating: false,
        isSplitting: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isUpdating: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const updateTeam = async (team: MinimalTeam | null) => {
    try {
      await api.updateTransactionTeam(transactionId, team?.id || null);
      if (!mounted.current) return;
      onUpdate(
        {
          ...transaction,
          teamId: team?.id || null,
          teamName: team?.name || null,
        },
        accountingTransactions
      );
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const updateSubcategory = async (
    category: MerchantCategory,
    subcategoryId: string | null,
    accountingTransaction: AccountingTransaction
  ) => {
    try {
      const data = await api.updateAccountingTransactions(
        transactionId,
        organization!.id,
        accountingTransactions.map((item) => {
          const {
            amount,
            subcategoryAutomatched,
            vatRateAutomatched,
            ...updatedItem
          } = item;
          return updatedItem.id === accountingTransaction.id
            ? { ...updatedItem, subcategoryId, category }
            : updatedItem;
        })
      );
      if (!mounted.current) return;
      onUpdate(
        data.accountingTransactions.length === 1
          ? { ...transaction, category }
          : transaction,
        data.accountingTransactions
      );
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const updateAccountingTransactionPartially = async (
    id: string,
    accountingTransactionData: Partial<AccountingTransaction>,
    transactionData: Partial<Transaction> = {}
  ) => {
    try {
      const data = await api.updateAccountingTransactions(
        transactionId,
        organization!.id,
        accountingTransactions.map((item) => {
          const {
            amount,
            subcategoryAutomatched,
            vatRateAutomatched,
            ...updatedItem
          } = item;
          return updatedItem.id === id
            ? { ...updatedItem, ...accountingTransactionData }
            : updatedItem;
        })
      );
      if (!mounted.current) return;
      onUpdate(
        { ...transaction, ...transactionData },
        data.accountingTransactions
      );
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const updateCustomField = async (
    id: string,
    updatedCustomField: UpdateAccTxnCustomFieldPayload,
    createCustomField: boolean
  ) => {
    try {
      createCustomField
        ? await api.createAccTxnCustomFields(id, updatedCustomField)
        : await api.updateAccTxnCustomFields(id, updatedCustomField);

      if (!mounted.current) return;
      refetchAccountingTransactions();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const updateComment = async (comment: string) => {
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      await api.updateTransaction(transactionId, comment);
      if (!mounted.current) return;
      onUpdate({ ...transaction, comment }, accountingTransactions);
      setState((prevState) => ({
        ...prevState,
        isUpdating: false,
        isCommentEditing: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isCommentEditing: true,
        isUpdating: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const updateReceiptData = async (
    key: 'receiptNumber' | 'receiptDate',
    value: string
  ) => {
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      await api.updateReceipt({ transactionId, [key]: value });
      if (!mounted.current) return;
      onUpdate({ ...transaction, [key]: value }, accountingTransactions);
      refetchReceipts();
      setState((prevState) => ({
        ...prevState,
        isUpdating: false,
        ...(key === 'receiptNumber'
          ? { isReceiptNumberEditing: false }
          : { isReceiptDateEditing: false }),
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isUpdating: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const changeSupplier = async (supplier?: Supplier) => {
    try {
      setState((state) => ({ ...state, isUpdating: true }));
      supplier
        ? await api.assignSupplier({
            organizationId: supplier.organizationId,
            supplierId: supplier.id,
            transactionId,
          })
        : await api.unAssignSupplier({
            organizationId: organization!.id,
            transactionId,
          });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isUpdating: false,
      }));
      onUpdate(
        {
          ...transaction,
          supplier: supplier
            ? {
                id: supplier.id,
                name: supplier.name,
                accountNumber: supplier.accountNumber,
                autoMatched: false,
              }
            : null,
        },
        accountingTransactions
      );
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isUpdating: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const updateReview = async (
    reviewStatus:
      | TransactionReviewStatus.needsReview
      | TransactionReviewStatus.approved
  ) => {
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      await api.updateTransactionsReview({
        transactionIds: [transactionId],
        reviewStatus,
      });
      if (!mounted.current) return;
      updateTransactionsCounters();
      onRefresh();
      onReviewUpdated?.(reviewStatus);
      setState((prevState) => ({ ...prevState, isUpdating: false }));
      if (reviewStatus === TransactionReviewStatus.needsReview) {
        enqueueSnackbar(t('transactionDetails.reviewStatusResetSuccess'));
      } else if (reviewStatus === TransactionReviewStatus.approved) {
        enqueueSnackbar(t('transactionDetails.reviewStatusApprovedSuccess'));
      }
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isUpdating: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const receiptsHidden =
    (!featureModules.RECEIPT_MANAGEMENT && !transaction.platformFeeCard) ||
    transaction.status === TransactionStatus.declined ||
    transaction.simpleType === TransactionSimpleType.statusInquiry;
  const commentFieldHidden =
    transaction.status === TransactionStatus.declined ||
    transaction.simpleType === TransactionSimpleType.statusInquiry;
  const transactionFieldsHidden =
    commentFieldHidden || transaction.status === TransactionStatus.reversed;

  const accountingFeaturesEnabled = featureModules.ACCOUNTING_FEATURES;

  const isSplitTransactionsButtonShown =
    canUser('accounting-transaction:update') &&
    accountingFeaturesEnabled &&
    [TransactionStatus.pending, TransactionStatus.confirmed].includes(
      transaction.status
    ) &&
    transaction.simpleType !== TransactionSimpleType.statusInquiry &&
    accountingTransactions.length === 1 &&
    !state.isSplitting &&
    !isTransactionReadonly(transaction, isAdminApp);
  const isAccountingTxEditDisabled =
    transactionFieldsHidden ||
    !canUser('accounting-transaction:update') ||
    isTransactionReadonly(transaction, isAdminApp);

  return (
    <>
      <DetailsDrawerHeader pb={2}>
        <TransactionDetailsHeader
          transaction={transaction}
          accountingTransactions={accountingTransactions}
          isReceiptPreviewDialogOpen={isReceiptPreviewDialogOpen}
          onResetReview={() =>
            updateReview(TransactionReviewStatus.needsReview)
          }
          onUpdate={onRefresh}
          onReviewUpdated={onReviewUpdated}
        />
      </DetailsDrawerHeader>

      <DetailsDrawerContent pb={4}>
        {canUser('transaction-review:flag', transaction) &&
          canUser('transaction-review:approve', transaction, managedTeamsIds) &&
          (isNeedsReviewVisible() || isNeedsReviewOnExportPageVisible()) && (
            <ReviewTransaction
              transaction={transaction}
              onUpdate={(reviewStatus) => {
                updateTransactionsCounters();
                onRefresh();
                onReviewUpdated?.(reviewStatus);
              }}
              onApproveTransaction={() =>
                updateReview(TransactionReviewStatus.approved)
              }
            />
          )}

        {!isReceiptPreviewDialogOpen &&
          isExportPage &&
          canUser('transaction:export') &&
          onChangeExportStatus &&
          !isNeedsReviewOnExportPageVisible() && (
            <ExportTransaction
              transactionId={transactionId}
              onChangeExportStatus={onChangeExportStatus}
            />
          )}

        <TransactionReviewComment
          reviewStatus={transaction.reviewStatus}
          reviewFlagReason={transaction.reviewFlagReason}
          reviewComment={transaction.reviewComment}
          reviewedAt={transaction.reviewedAt}
          reviewerFullName={transaction.reviewerFullName}
          reviewerEmail={transaction.reviewerEmail}
        />

        {transaction.status === TransactionStatus.declined && (
          <DeclinedTransactionInfoSection transaction={transaction} />
        )}

        {canUser('transaction-dispute:view') && transaction.disputed && (
          <TransactionDisputeSection />
        )}

        {isExportPage && !isReceiptPreviewDialogOpen && (
          <ReuploadReceiptsError
            transaction={transaction}
            receipts={receipts}
            onReupload={onReceiptPreviewDialogOpen}
          />
        )}

        {isExportPage && transaction.exportError && (
          <WarningContainer>
            <ActionBox sx={{ borderRadius: 1 }}>
              <ActionBoxTitle>
                {t('transactionDetails.exportWarnings')}
              </ActionBoxTitle>
              {transaction.exportError.error?.error}
              <ActionBoxActions>
                <Button href={'mailto:' + SUPPORT_EMAIL}>
                  {t('transactionDetails.exportWarningsButton')}
                </Button>
              </ActionBoxActions>
            </ActionBox>
          </WarningContainer>
        )}

        <Box p={3}>
          <Typography variant="overline" component="div" mb={1.5}>
            {t('transactionDetails.txDetailsBlockTitle')}
          </Typography>

          {isAdminApp &&
            transaction.merchant &&
            organizationMerchantsPageEnabled && (
              <Merchant
                label={t('transactionDetails.merchant')}
                orgId={transaction.organizationId}
                merchant={transaction.merchant}
              />
            )}

          {canUser('supplier:view') &&
            isExportPage &&
            suppliersEnabled &&
            accountingSettings?.supplierEnabled &&
            accountingFeaturesEnabled &&
            !transactionFieldsHidden && (
              <SupplierSelect
                supplier={transaction.supplier}
                isTxFrozen={isTransactionReadonly(transaction, isAdminApp)}
                onChange={changeSupplier}
              />
            )}

          <CardSection transaction={transaction} isAdminApp={isAdminApp} />

          <Account cardAccountId={transaction.cardAccountId} />

          {accountingSettings?.commentEnabled && !commentFieldHidden && (
            <Comment
              transaction={transaction}
              isEditing={state.isCommentEditing}
              onEditChange={(isCommentEditing) => {
                setState((state) => ({ ...state, isCommentEditing }));
              }}
              onUpdate={updateComment}
            />
          )}
        </Box>

        {(transaction.reviewStatus !== TransactionReviewStatus.flagged ||
          transaction.reviewFlagReason !== ReviewFlagReason.privateExpense) && (
          <>
            {!receiptsHidden &&
              (transaction.receiptUploadEnabled || !!receipts.length) && (
                <TransactionReceipts
                  key={transaction.transactionId}
                  transaction={transaction}
                  receipts={receipts}
                  receiptDateStatus={receiptDateStatus}
                  isReceiptDateEditing={state.isReceiptDateEditing}
                  receiptNumberStatus={receiptNumberStatus}
                  isReceiptNumberEditing={state.isReceiptNumberEditing}
                  onUpdate={onReceiptUpdate}
                  onRefresh={onRefresh}
                  isReceiptPreviewDialogOpen={isReceiptPreviewDialogOpen}
                  onReceiptPreviewDialogOpen={onReceiptPreviewDialogOpen}
                  onReceiptNumberEditChange={(isReceiptNumberEditing) =>
                    setState((prevState) => ({
                      ...prevState,
                      isReceiptNumberEditing,
                    }))
                  }
                  onReceiptDateEditChange={(isReceiptDateEditing) =>
                    setState((prevState) => ({
                      ...prevState,
                      isReceiptDateEditing,
                    }))
                  }
                  onReceiptDataUpdate={updateReceiptData}
                />
              )}

            <Box
              p={3}
              borderTop={(theme) => `1px solid ${theme.palette.divider}`}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
              >
                <Typography variant="overline">
                  {t('transactionDetails.accDetailsBlockTitle')}
                </Typography>

                {isSplitTransactionsButtonShown && (
                  <Tooltip
                    title={
                      transaction.reviewStatus ===
                      TransactionReviewStatus.flagged
                        ? t(
                            'transactionDetails.splitForFlaggedTransactionDisabledTooltip'
                          )
                        : ''
                    }
                  >
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => {
                        if (
                          transaction.reviewStatus ===
                          TransactionReviewStatus.flagged
                        ) {
                          return;
                        }
                        setState((prevState) => ({
                          ...prevState,
                          isSplitting: true,
                        }));
                      }}
                      startIcon={<PlusIcon />}
                      disableRipple={
                        transaction.reviewStatus ===
                        TransactionReviewStatus.flagged
                      }
                    >
                      {t('transactionDetailsMenu.split')}
                    </Button>
                  </Tooltip>
                )}

                {accountingFeaturesEnabled &&
                  !isAccountingTxEditDisabled &&
                  accountingTransactions.length > 1 &&
                  !state.isSplitting && (
                    <Button
                      size="small"
                      variant="text"
                      startIcon={<PencilSimpleIcon />}
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          isSplitting: true,
                        }))
                      }
                    >
                      {t('transactionDetails.editSplitTransactions')}
                    </Button>
                  )}
              </Box>

              <AccountingTransactions
                isExportPage={isExportPage}
                isEditing={state.isSplitting}
                isEditDisabled={isAccountingTxEditDisabled}
                transaction={transaction}
                accountingTransactions={accountingTransactions}
                transactionFieldsHidden={transactionFieldsHidden}
                onToggleEdit={(isSplitting) =>
                  setState((prevState) => ({ ...prevState, isSplitting }))
                }
                onUpdateAccountingTransactions={updateAccountingTransactions}
                onUpdateSubcategory={updateSubcategory}
                onUpdateVatRate={updateAccountingTransactionPartially}
                onUpdateProject={updateAccountingTransactionPartially}
                onUpdateCustomField={updateCustomField}
              />

              <TeamsSelect
                teams={teams}
                isExportPage={isExportPage}
                teamId={transaction.teamId}
                teamName={transaction.teamName}
                onChange={updateTeam}
                transaction={transaction}
                disabled={transactionFieldsHidden}
              />
            </Box>

            <AdditionalInfoSection transaction={transaction} />
          </>
        )}
      </DetailsDrawerContent>

      <LoaderWithOverlay loading={state.isUpdating} />
    </>
  );
};

export default TransactionDetailsContent;

import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UserAddIcon } from 'assets/icons/interface/userAdd.svg';
import { Button as Mui4Button } from 'components/Button';
import Tooltip from 'components/Tooltip';
import { useGlobalState } from 'context/GlobalState';
import { InviteMemberDialog } from 'domains/member/dialogs';
import { useAvailableRoles } from 'domains/member/utils';
import { Button, ButtonProps, UserIcon } from 'elements';
import useInviteDialogThresholdExceededMessage from 'hooks/useInviteDialogThresholdExceededMessage';

interface Props extends ButtonProps {
  dataTestId?: string;
  dataIntercomTarget?: string;
  isNewDesign?: boolean;
  onSuccess?: () => void;
  onDialogOpen?: () => void;
}

const InviteMemberButtonWithDialog = ({
  dataTestId,
  dataIntercomTarget,
  isNewDesign = false,
  onSuccess,
  onDialogOpen,
  ...buttonProps
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { subscriptionPlan },
  } = useGlobalState();
  const thresholdExceededMessage = useInviteDialogThresholdExceededMessage();
  const { isInviteMemberAllowed } = useAvailableRoles();
  const [isInviteMemberDialogOpen, setIsInviteMemberDialogOpen] = useState(
    false
  );

  if (!isInviteMemberAllowed || !subscriptionPlan) return null;

  const isButtonDisabled = () =>
    subscriptionPlan.maxActiveMembersCount !== null &&
    subscriptionPlan.activeAndInvitedMembersCount >=
      subscriptionPlan.maxActiveMembersCount;

  return (
    <>
      <Tooltip title={isButtonDisabled() ? thresholdExceededMessage : ''}>
        <Box display="inline-block">
          {isNewDesign ? (
            <Button
              data-test-id={dataTestId}
              data-intercom-target={dataIntercomTarget}
              onClick={() => {
                setIsInviteMemberDialogOpen(true);
                onDialogOpen && onDialogOpen();
              }}
              startIcon={<UserIcon />}
              disabled={isButtonDisabled()}
              {...buttonProps}
            >
              {t('inviteMemberButtonWithDialog.inviteMember')}
            </Button>
          ) : (
            <Mui4Button
              data-test-id={dataTestId}
              data-intercom-target={dataIntercomTarget}
              onClick={() => {
                setIsInviteMemberDialogOpen(true);
                onDialogOpen && onDialogOpen();
              }}
              startIcon={<UserAddIcon />}
              disabled={isButtonDisabled()}
            >
              {t('inviteMemberButtonWithDialog.inviteMember')}
            </Mui4Button>
          )}
        </Box>
      </Tooltip>
      <InviteMemberDialog
        subscriptionPlan={subscriptionPlan}
        open={isInviteMemberDialogOpen}
        onClose={() => setIsInviteMemberDialogOpen(false)}
        onSuccess={() => {
          setIsInviteMemberDialogOpen(false);
          if (onSuccess) onSuccess();
        }}
      />
    </>
  );
};

export default InviteMemberButtonWithDialog;

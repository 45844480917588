import BasLogo from 'assets/images/integrationAvatars/bas.png';
import { ReactComponent as BezalaLogo } from 'assets/svg/integrations/bezalaLogo.svg';
import { ReactComponent as CandisLogo } from 'assets/svg/integrations/candisLogo.svg';
import { ReactComponent as CirculaLogo } from 'assets/svg/integrations/circulaLogo.svg';
import { ReactComponent as ConfermaLogo } from 'assets/svg/integrations/confermaLogo.svg';
import { ReactComponent as KlippaLogo } from 'assets/svg/integrations/klippaLogo.svg';
import { ReactComponent as MobilexpenseLogo } from 'assets/svg/integrations/mobilexpenseLogo.svg';
import { ReactComponent as MocoappLogo } from 'assets/svg/integrations/mocoappLogo.svg';
import { ReactComponent as ScopevisioLogo } from 'assets/svg/integrations/scopevisioLogo.svg';
import { ReactComponent as ViaxoftLogo } from 'assets/svg/integrations/viaxoftLogo.svg';
import { ReactComponent as VoxelLogo } from 'assets/svg/integrations/voxelLogo.svg';
import { PartnerIds, PartnerIdType } from 'services/constants';

const PartnerLogo = ({ partnerId }: { partnerId: PartnerIdType }) => {
  if (partnerId === PartnerIds.bezala) return <BezalaLogo />;
  if (partnerId === PartnerIds.viaxoft) return <ViaxoftLogo />;
  if (partnerId === PartnerIds.candis) return <CandisLogo />;
  if (
    partnerId === PartnerIds.circula ||
    partnerId === PartnerIds.circulaDemo ||
    partnerId === PartnerIds.circulaEmbedded ||
    partnerId === PartnerIds.circulaFullyEmbedded
  )
    return <CirculaLogo />;
  if (
    partnerId === PartnerIds.mobilexpenseDeclaree ||
    partnerId === PartnerIds.mobilexpenseMXP
  )
    return <MobilexpenseLogo />;
  if (partnerId === PartnerIds.klippa) return <KlippaLogo />;
  if (partnerId === PartnerIds.mocoapp) return <MocoappLogo />;
  if (partnerId === PartnerIds.voxel) return <VoxelLogo />;
  if (partnerId === PartnerIds.conferma) return <ConfermaLogo />;
  // should be replaced once we have svg
  if (partnerId === PartnerIds.bas)
    return <img src={BasLogo} alt="BAS" height={45} />;
  if (partnerId === PartnerIds.scopevisio) return <ScopevisioLogo />;
  return null;
};

export default PartnerLogo;

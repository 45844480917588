import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { getNavigationPath } from 'domains/onboarding/utils';
import { Box, Button } from 'elements';
import { TaskNavigationPropsBase } from 'services/constants';

interface Props extends TaskNavigationPropsBase {
  disabled: boolean;
  onSuccess: () => void;
  successBtnText?: string;
}

const OnboardingTaskActions = ({
  isReadOnly,
  taskNavigationItems,
  disabled,
  successBtnText,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();
  const { sectionKey, groupId } = useParams<{
    sectionKey: string;
    groupId?: string;
  }>();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    nextTaskId,
    prevTaskId,
    isTaskNavigationEnabled,
  } = taskNavigationItems;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mt={4}
    >
      <Button
        variant="text"
        component={RouterLink}
        to={{
          pathname: getNavigationPath(organization!.id, sectionKey, {
            taskId: prevTaskId,
            groupId,
          }),
        }}
      >
        {t('common.button.back')}
      </Button>

      <Box sx={{ 'a + button': { ml: 2 } }}>
        {isTaskNavigationEnabled && nextTaskId && (
          <Button
            component={RouterLink}
            variant="text"
            to={{
              pathname: getNavigationPath(organization!.id, sectionKey, {
                taskId: nextTaskId,
                groupId,
              }),
            }}
          >
            {t('common.button.skip')}
          </Button>
        )}

        {!isReadOnly && (
          <Button disabled={disabled} onClick={onSuccess}>
            {successBtnText || t('common.button.continue')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default OnboardingTaskActions;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import ConfirmDialog from 'components/ConfirmDialogV2';
import FormatMoney from 'components/FormatMoney';
import FormatPercent from 'components/FormatPercent';
import { useGlobalState } from 'context/GlobalState';
import {
  PayoutPaymentDialog,
  TopUpAccountDialog,
} from 'domains/billing/dialogs';
import {
  useIsAllowedToTopUpCardAccount,
  useIsPayoutFromCardAccountAllowed,
} from 'domains/billing/hooks';
import { CreateCardAccountDialog } from 'domains/card/dialogs';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import {
  ArrowLineUpIcon,
  BankIcon,
  Box,
  Button,
  ClockCountdownIcon,
  Grid,
  IconButton,
  LinearProgress,
  LoadingButton,
  Paper,
  StarIcon,
  Tooltip,
  Typography,
  WithdrawIcon,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PageContent, PageHeader, PageTitle, PageTitleActions } from 'layout';
import {
  CardAccount,
  CardAccountCurrency,
  CardAccountStatus,
  OrganizationAccountType,
  OrganizationStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import {
  getGenericErrorMsg,
  getLimitPercentage,
  getPath,
} from 'services/utils';
import { didOrgOnboardingStepPassed } from 'services/utils/onboarding';

interface State {
  isCreateCardAccountDialogOpen: boolean;
  isCardAccountNotificationDialogOpen: boolean;
  isTopUpAccountDialogOpen: boolean;
  isPayoutPaymentDialogOpen: boolean;
  selectedCardAccount: CardAccount | null;
  isAvailableCurrenciesLoading: boolean;
  availableCurrencies: CardAccountCurrency[];
}

const CardAccountsPage = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const isPayoutFromCardAccountAllowed = useIsPayoutFromCardAccountAllowed();
  const isTopUpAllowed = useIsAllowedToTopUpCardAccount();
  const {
    dispatch,
    state: { organization, cardAccounts, featureModules },
  } = useGlobalState();
  const getCardAccountName = useCardAccountNameGetter();
  const didOrgPassOnboardingRisk = didOrgOnboardingStepPassed(
    organization!.status,
    OrganizationStatus.onboardingRiskReview
  );
  const [state, setState] = useState<State>({
    isCreateCardAccountDialogOpen: false,
    isCardAccountNotificationDialogOpen: false,
    isTopUpAccountDialogOpen: false,
    isPayoutPaymentDialogOpen: false,
    selectedCardAccount: null,
    isAvailableCurrenciesLoading: featureModules.MULTI_CURRENCY_BILLING,
    availableCurrencies: [],
  });

  const fetchAvailableCardAccountCurrencies = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isAvailableCurrenciesLoading: true,
      }));

      const {
        currencies: availableCurrencies,
      } = await api.getAvailableCardAccountCurrencies(organization!.id);

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isAvailableCurrenciesLoading: false,
        availableCurrencies,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (featureModules.MULTI_CURRENCY_BILLING) {
      fetchAvailableCardAccountCurrencies();
    }
  }, [featureModules.MULTI_CURRENCY_BILLING]);

  const isPayoutAllowed = (cardAccount: CardAccount) => {
    if (cardAccount.balance.value.value <= 0) return false;
    return isPayoutFromCardAccountAllowed(cardAccount);
  };

  const renderTileHeader = (account: CardAccount) => {
    return (
      <Box display="flex" alignItems="flex-start">
        <Box display="flex" flexGrow="1" alignItems="center" minWidth={0}>
          <Typography variant="h6" noWrap>
            {getCardAccountName(account)}
          </Typography>
          {account.defaultAccount.value && (
            <Tooltip title={t('cardAccountsPage.tooltips.defaultAccount')}>
              <StarIcon
                fontSize="small"
                sx={{ ml: 1, color: 'text.secondary' }}
              />
            </Tooltip>
          )}
        </Box>
        <Tooltip title={t('cardAccountsPage.tooltips.topUp')}>
          <Box component="span" ml={1}>
            <IconButton
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  selectedCardAccount: account,
                  isTopUpAccountDialogOpen: true,
                }))
              }
              disabled={!isTopUpAllowed(account)}
              size="small"
              color="primary-contained"
            >
              <ArrowLineUpIcon fontSize="small" />
            </IconButton>
          </Box>
        </Tooltip>
        <Tooltip title={t('cardAccountsPage.tooltips.withdraw')}>
          <Box component="span" ml={1}>
            <IconButton
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  selectedCardAccount: account,
                  isPayoutPaymentDialogOpen: true,
                }))
              }
              disabled={!isPayoutAllowed(account)}
              size="small"
            >
              <WithdrawIcon fontSize="small" />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
    );
  };

  const renderTileContent = (account: CardAccount) => {
    if (!didOrgPassOnboardingRisk)
      return (
        <Box flexGrow={1} pt={4}>
          <Typography variant="h5" mt={1}>
            -
          </Typography>
        </Box>
      );

    if (account.accountType.value === OrganizationAccountType.prefunded) {
      return (
        <Box flexGrow={1} pt={4}>
          <Typography variant="h5" mt={1}>
            <FormatMoney value={account.balance.value} />
          </Typography>
        </Box>
      );
    }

    const { limitPercentage } = getLimitPercentage({
      availableLimit: account.availableLimit.value,
      limit: account.creditLimit.value,
    });
    const isPercentageLow = limitPercentage <= 20;

    return (
      <Box flexGrow={1} pt={4}>
        <Typography
          variant="h5"
          mt={1}
          sx={{ color: isPercentageLow ? 'warning.main' : 'text.primary' }}
        >
          <FormatMoney value={account.availableLimit.value} />
        </Typography>

        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <LinearProgress
              value={limitPercentage}
              color={isPercentageLow ? 'warning' : 'info'}
            />
          </Box>
          <Typography variant="caption" ml={1}>
            <FormatPercent
              value={Math.round(limitPercentage)}
              fractionalPart={false}
            />
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderTileFooter = (account: CardAccount) => {
    return (
      <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
        {account.accountType.value === OrganizationAccountType.credit && (
          <>
            <Box>
              <Typography
                component="div"
                variant="caption"
                color="text.secondary"
              >
                {t('cardAccountsPage.creditLimit')}
              </Typography>
              <Typography component="div" variant="caption">
                {didOrgPassOnboardingRisk ? (
                  <FormatMoney value={account.creditLimit.value} />
                ) : (
                  '-'
                )}
              </Typography>
            </Box>
            <Box pl={5} flexGrow={1}>
              <Typography
                component="div"
                variant="caption"
                color="text.secondary"
              >
                {t('cardAccountsPage.paymentTerms')}
              </Typography>
              <Typography component="div" variant="caption">
                {didOrgPassOnboardingRisk
                  ? t(`paymentFrequencyTypes.${account.paymentFrequency.value}`)
                  : '-'}
              </Typography>
            </Box>
          </>
        )}
        <Button
          size="small"
          variant="outlined"
          component={Link}
          to={
            generatePath(getPath('accounting'), { orgId: organization!.id }) +
            `?cardAccountId=${account.id}`
          }
        >
          {t('cardAccountsPage.viewTransactions')}
        </Button>
      </Box>
    );
  };

  const createAccountButtonDisabledReason =
    featureModules.MULTI_CURRENCY_BILLING &&
    state.availableCurrencies.length === 0
      ? t('cardAccountsPage.cardAccountsWithAllCurrenciesCreated')
      : '';

  return (
    <>
      <PageHeader>
        <PageTitle title={t('cardAccountsPage.title')}>
          {canUser('card-accounts:create') && (
            <PageTitleActions>
              <Tooltip
                disableHoverListener={!createAccountButtonDisabledReason}
                title={createAccountButtonDisabledReason}
              >
                <span>
                  <LoadingButton
                    startIcon={<BankIcon />}
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        isCreateCardAccountDialogOpen: true,
                      }))
                    }
                    loading={state.isAvailableCurrenciesLoading}
                    disabled={!!createAccountButtonDisabledReason}
                  >
                    {t('cardAccountsPage.createAccount')}
                  </LoadingButton>
                </span>
              </Tooltip>
            </PageTitleActions>
          )}
        </PageTitle>
      </PageHeader>
      <PageContent>
        <Grid container spacing={3}>
          {cardAccounts.map((account) => (
            <Grid key={account.id} item>
              <Paper
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 500,
                  height: 238,
                  p: 3,
                }}
              >
                {renderTileHeader(account)}
                {account.status.value !== CardAccountStatus.pending &&
                  renderTileContent(account)}
                {account.status.value !== CardAccountStatus.pending &&
                  renderTileFooter(account)}
                {account.status.value === CardAccountStatus.pending && (
                  <Box mt="auto" textAlign="center" color="text.secondary">
                    <ClockCountdownIcon />
                    <Typography mx={4} my={2} variant="body2">
                      {t('cardAccountsPage.pendingAccountLabel')}
                    </Typography>
                  </Box>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </PageContent>
      <CreateCardAccountDialog
        open={state.isCreateCardAccountDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isCreateCardAccountDialogOpen: false,
          }))
        }
        onSuccess={(cardAccount: CardAccount) => {
          fetchAvailableCardAccountCurrencies();
          setState((prevState) => ({
            ...prevState,
            isCreateCardAccountDialogOpen: false,
          }));

          dispatch({
            type: 'SET_ORGANIZATION_DATA',
            payload: {
              cardAccounts: [...cardAccounts, cardAccount],
            },
          });

          if (cardAccount.status.value === CardAccountStatus.active) {
            setState((prevState) => ({
              ...prevState,
              isTopUpAccountDialogOpen: true,
              selectedCardAccount: cardAccount,
            }));
          } else if (cardAccount.status.value === CardAccountStatus.pending) {
            setState((prevState) => ({
              ...prevState,
              setIsCardAccountNotificationDialogOpen: true,
            }));
          }
        }}
        availableCurrencies={state.availableCurrencies}
      />
      <ConfirmDialog
        open={state.isCardAccountNotificationDialogOpen}
        title={t('cardAccountNotificationDialog.title')}
        description={t('cardAccountNotificationDialog.content')}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isCardAccountNotificationDialogOpen: false,
          }))
        }
        onSuccess={() =>
          setState((prevState) => ({
            ...prevState,
            isCardAccountNotificationDialogOpen: false,
          }))
        }
        confirmButtonProps={{
          children: t('cardAccountNotificationDialog.done'),
        }}
      />
      <TopUpAccountDialog
        open={state.isTopUpAccountDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isTopUpAccountDialogOpen: false,
            selectedCardAccount: null,
          }))
        }
        selectedCardAccount={state.selectedCardAccount || null}
      />
      <PayoutPaymentDialog
        open={state.isPayoutPaymentDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isPayoutPaymentDialogOpen: false,
            selectedCardAccount: null,
          }))
        }
        onSuccess={() =>
          setState((prevState) => ({
            ...prevState,
            isPayoutPaymentDialogOpen: false,
            selectedCardAccount: null,
          }))
        }
        selectedCardAccount={state.selectedCardAccount || undefined}
      />
    </>
  );
};

export default withPageConfig(CardAccountsPage, {
  permission: 'card-accounts-page:visit',
  featureModule: (featureModules) =>
    featureModules.MULTI_CARD_ACCOUNT || featureModules.MULTI_CURRENCY_BILLING,
});

import { useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { internalRootPaths } from 'components/App';
import ConfirmDialog from 'components/ConfirmDialogV2';
import WidgetError from 'components/WidgetError';
import {
  MerchantInfoAccordion,
  MerchantLogo,
  MerchantSplitTypeBadge,
  MerchantTransactionsCountAndVolume,
  RelatedMerchants,
} from 'domains/merchant/components';
import { Box, Link, LoaderWithOverlay, Paper, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  DetailsDrawer,
  DetailsDrawerContent,
  DetailsDrawerHeader,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import {
  Merchant,
  MerchantDe42,
  MerchantDetails,
  MerchantSplitType,
  MerchantStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import MerchantDetailsMenu from './MerchantDetailsMenu';
import MerchantNote from './MerchantNote';
import MerchantSplitDialog from './MerchantSplitDialog';
import MerchantStatusSelect from './MerchantStatusSelect';
import MerchantUnmergeDialog from './MerchantUnmergeDialog';

const getMerchantId42Groups = (merchantsDe42: MerchantDe42[]) => {
  const groupIds = merchantsDe42.map((item) => item.autoGroupId);
  const uniqueGroupIds = Array.from(new Set(groupIds));
  return uniqueGroupIds.map((item) =>
    merchantsDe42.filter((de42) => de42.autoGroupId === item)
  );
};

const getMainMerchantId = (merchant: MerchantDetails) => {
  const mainMerchant = merchant.relatedMerchants?.find(
    (item) => item.splitType === MerchantSplitType.mainMerchant
  );
  return mainMerchant?.id || null;
};

interface State {
  isLoading: boolean;
  merchant: MerchantDetails | null;
  error: unknown;
  isMerchantStatusUpdating: boolean;
  isUnmergeDialogOpen: boolean;
  isSplitDialogOpen: boolean;
  isUnsplitDialogOpen: boolean;
  isUnsplitting: boolean;
  isNoteEditing: boolean;
}

interface Props extends DetailsDrawerProps {
  onUpdate: (merchant: Merchant) => void;
  onMerchantMerge: (merchant: MerchantDetails) => void;
  onMerchantUnmerge: (merchant: MerchantDetails) => void;
  onMerchantSplit: (merchant: MerchantDetails) => void;
  onMerchantUnsplit: (mainMerchantId: string | null) => void;
}

const MerchantDetailsPage = ({
  onUpdate,
  onMerchantMerge,
  onMerchantUnmerge,
  onMerchantSplit,
  onMerchantUnsplit,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const canUser = useCanUser();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const { merchantId } = useParams<{ merchantId: string }>();
  const idRef = useRef(merchantId);
  const mounted = useMounted();
  const [state, setState] = useState<State>({
    isLoading: true,
    merchant: null,
    error: null,
    isMerchantStatusUpdating: false,
    isUnmergeDialogOpen: false,
    isSplitDialogOpen: false,
    isUnsplitDialogOpen: false,
    isUnsplitting: false,
    isNoteEditing: false,
  });

  const merchantId42Groups = useMemo(
    () =>
      state.merchant?.merchantsDe42
        ? getMerchantId42Groups(state.merchant.merchantsDe42)
        : [],
    [state.merchant?.merchantsDe42]
  );

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const merchant = await api.getMerchantDetails(merchantId);
      if (!mounted.current || merchantId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        merchant,
        error: null,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current || merchantId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        error,
        merchant: null,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!merchantId) return;
    idRef.current = merchantId;
    getData();
    setState((prevState) => ({ ...prevState, isNoteEditing: false }));
  }, [merchantId]);

  const handleMerchantStatusUpdate = async (status: MerchantStatus) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isMerchantStatusUpdating: true,
      }));
      const merchantData = await api.updateMerchantStatus(merchantId, status);
      if (!mounted.current || merchantId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        merchant: merchantData,
        isMerchantStatusUpdating: false,
      }));
      onUpdate(merchantData);
    } catch (error) {
      if (!mounted.current || merchantId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        isMerchantStatusUpdating: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const handleUnsplitMerchant = async () => {
    if (state.isUnsplitting) return;
    try {
      setState((prevState) => ({ ...prevState, isUnsplitting: true }));
      await api.unsplitMerchant(state.merchant!.id);
      if (!mounted.current || merchantId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        isUnsplitting: false,
        isUnsplitDialogOpen: false,
      }));
      const mainMerchantId = getMainMerchantId(state.merchant!);
      onMerchantUnsplit(mainMerchantId);
    } catch (error) {
      if (!mounted.current || merchantId !== idRef.current) return;
      setState((prevState) => ({ ...prevState, isUnsplitting: false }));
      enqueueSnackbar(t('int.merchantDetailsPage.unsplitMerchantErrorMsg'), {
        variant: 'error',
      });
      logError(error);
    }
  };

  const handleUpdateNote = async (note: string | null) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const merchant = state.merchant!;
      const data = await api.updateMerchant(state.merchant!.id, {
        displayName: merchant.displayName,
        legalName: merchant.legalName,
        url: merchant.url || '',
        street: merchant.street,
        postalCode: merchant.postalCode,
        city: merchant.city,
        state: merchant.state,
        vatNumber: merchant.vatNumber,
        country: merchant.country,
        companyRegistry: merchant.companyRegistry || '',
        note,
      });
      if (!mounted.current || merchantId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        merchant: data,
        isNoteEditing: false,
        isLoading: false,
      }));
      onUpdate(merchant);
    } catch (error) {
      if (!mounted.current || merchantId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        isNoteEditing: true,
        isLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <DetailsDrawer
        {...props}
        actionsComponent={
          canUser('merchant:change') &&
          !!state.merchant && (
            <MerchantDetailsMenu
              merchant={state.merchant}
              hasMultipleDe42Ids={merchantId42Groups.length > 1}
              onMerge={onMerchantMerge}
              onUnmerge={() =>
                setState((prevState) => ({
                  ...prevState,
                  isUnmergeDialogOpen: true,
                }))
              }
              onSplit={() =>
                setState((prevState) => ({
                  ...prevState,
                  isSplitDialogOpen: true,
                }))
              }
              onUnsplit={() =>
                setState((prevState) => ({
                  ...prevState,
                  isUnsplitDialogOpen: true,
                }))
              }
              onUpdate={(merchant) => {
                setState((prevState) => ({ ...prevState, merchant }));
                onUpdate(merchant);
              }}
            />
          )
        }
      >
        {state.merchant && (
          <>
            <DetailsDrawerHeader pb={0}>
              <Box display="flex" alignItems="center" mb={3}>
                <MerchantLogo
                  size="large"
                  url={state.merchant.logoPath}
                  name={state.merchant.displayName}
                />
                <Box ml={2} overflow="hidden">
                  <Box display="flex">
                    <Typography variant="h5" noWrap>
                      {state.merchant.displayName}
                    </Typography>
                    <MerchantSplitTypeBadge
                      value={state.merchant.splitType}
                      sx={{ ml: 1 }}
                    />
                  </Box>
                  <MerchantStatusSelect
                    value={state.merchant.status}
                    disabled={
                      !canUser('merchant:change') ||
                      state.isMerchantStatusUpdating
                    }
                    onChange={handleMerchantStatusUpdate}
                  />
                </Box>
              </Box>
              <MerchantInfoAccordion merchant={state.merchant} />
            </DetailsDrawerHeader>
            <DetailsDrawerContent>
              <Box
                p={3}
                borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
              >
                <Typography variant="overline" component="div">
                  {t('int.merchantDetailsPage.details')}
                </Typography>

                {!!state.merchant.merchantsDe42?.length && (
                  <Paper variant="outlined" sx={{ mt: 1, px: 1.5, py: 1 }}>
                    <Typography variant="body2" noWrap>
                      {t('int.merchantDetailsPage.merchantId')}
                    </Typography>
                    <Typography
                      component="div"
                      variant="caption"
                      color="text.secondary"
                    >
                      {state.merchant.merchantsDe42.map(
                        (item, index, items) => (
                          <div key={item.merchantIdDe42}>{`${
                            item.merchantIdDe42
                          } - ${item.merchantName.toUpperCase()}${
                            index < items.length - 1 ? ',' : ''
                          }`}</div>
                        )
                      )}
                    </Typography>
                  </Paper>
                )}

                {state.merchant.splitRule && (
                  <Paper variant="outlined" sx={{ mt: 1, px: 1.5, py: 1 }}>
                    <Typography variant="body2" noWrap>
                      {t('int.merchantDetailsPage.matchCriteria')}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {t('int.merchantDetailsPage.strings')}{' '}
                      {state.merchant.splitRule.matchingStrings}
                    </Typography>
                  </Paper>
                )}

                <MerchantNote
                  id={state.merchant.id}
                  note={state.merchant.note}
                  isEditing={state.isNoteEditing}
                  onEditChange={(isNoteEditing) =>
                    setState((prevState) => ({ ...prevState, isNoteEditing }))
                  }
                  onUpdate={handleUpdateNote}
                />
              </Box>

              <RelatedMerchants
                generateMerchantLink={(merchantId) =>
                  generatePath(internalRootPaths.merchantsDetails, {
                    merchantId,
                  }) + location.search
                }
                title={t('int.merchantDetailsPage.relatedMerchants')}
                merchants={state.merchant.relatedMerchants}
              />

              <Box
                p={3}
                borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  py={0.5}
                  mb={1}
                >
                  <Typography variant="overline" component="div">
                    {t('int.merchantDetailsPage.analytics')}
                  </Typography>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    to={{
                      pathname: generatePath(
                        internalRootPaths.globalTransactions
                      ),
                      search: `merchantIds=${state.merchant.id}`,
                    }}
                  >
                    {t('int.merchantDetailsPage.seeAll')}
                  </Link>
                </Box>

                <MerchantTransactionsCountAndVolume
                  transactionsCount={state.merchant.transactionsCount}
                  transactionsVolume={state.merchant.transactionsVolume}
                />
              </Box>
            </DetailsDrawerContent>
          </>
        )}
        {state.error && <WidgetError onReload={getData} />}
        <LoaderWithOverlay loading={state.isLoading} />
      </DetailsDrawer>
      <MerchantUnmergeDialog
        open={state.isUnmergeDialogOpen}
        merchantId={merchantId}
        merchantsDe42={state.merchant?.merchantsDe42 || []}
        merchantId42Groups={merchantId42Groups}
        onMerchantUnmerge={(unmergedMerchant) => {
          getData();
          onMerchantUnmerge(unmergedMerchant);
        }}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isUnmergeDialogOpen: false,
          }))
        }
      />
      <MerchantSplitDialog
        open={state.isSplitDialogOpen}
        onClose={() =>
          setState((prevState) => ({ ...prevState, isSplitDialogOpen: false }))
        }
        onSuccess={(merchant) => {
          setState((prevState) => ({ ...prevState, isSplitDialogOpen: false }));
          getData();
          onMerchantSplit(merchant);
        }}
        merchant={state.merchant}
      />
      <ConfirmDialog
        open={state.isUnsplitDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isUnsplitDialogOpen: false,
          }))
        }
        onSuccess={handleUnsplitMerchant}
        loading={state.isUnsplitting}
        title={t('int.merchantDetailsPage.unsplitMerchantConfirmTitle')}
        description={
          <Trans
            i18nKey="int.merchantDetailsPage.unsplitMerchantConfirmDescription"
            components={{ br: <br /> }}
          />
        }
      />
    </>
  );
};

export default withDetailsDrawerWrapper(MerchantDetailsPage);

import React from 'react';
import { generatePath } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import AccountLinking from 'components/AccountLinking';
import Auth0Callback from 'components/App/Auth0Callback';
import AppErrorOverlay from 'components/AppErrorOverlay';
import OnboardingPage from 'components/OnboardingPage';
import {
  BillingAccountsApprovalPage,
  BillingPageRedirect,
  BillingPaymentsPage,
  BillingStatementsPage,
  BillPaymentBatchesPage,
  CashbackPaymentBatchesPage,
  CompensationBatchesPage,
  OrganizationSettlementsPage,
  PayoutBatchesPage,
  ProcessingAccountPage,
  ProgramSettlementsPage,
  ReceivableRepaymentsPage,
  SettlementAccountPage,
  StatementsPage,
} from 'domains/billing/pages';
import { CardAccountsPage, CardsPage } from 'domains/card/pages';
import { CreditAndCompliance } from 'domains/creditAndCompliance/layout';
import { MembersPage, TeamsPage, UsersPage } from 'domains/member/pages';
import { MerchantsPage, OrganizationMerchantsPage } from 'domains/merchant';
import OrganizationOnboardingRoutes from 'domains/onboarding/layout';
import {
  CrmIntegrationPage,
  DashboardPage,
  OrganizationsPage,
  PliantEarthPage,
} from 'domains/organization/pages';
import { EmbeddedPartnerPage } from 'domains/partner';
import { RewardsPage } from 'domains/reward/pages';
import { Settings } from 'domains/settings';
import {
  CashbackSettingsPage,
  GeneralTermsPage,
  SubscriptionSettingsPage,
} from 'domains/terms/pages';
import {
  AccountEntriesPage,
  AdminTransactionsPage,
  ExportPage,
  GlobalTransactionsPage,
  TransactionsFlaggedPage,
  TransactionsNeedsReviewPage,
} from 'domains/transaction/pages';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import OrganizationData from './OrganizationData';
import UserDataAndLayout from './UserDataAndLayout';
import {
  internalOrgPaths,
  internalOrgPathsArray,
  internalPathsArray,
  internalRootPaths,
} from './paths';

function Routes() {
  const { isOrgInOnboardingNew } = useIsOrgInOnboarding();

  return (
    <Switch>
      <Route path="/unauthorized">
        <AppErrorOverlay errorCode="UNAUTHORIZED" />
      </Route>
      <Route path="/account-linking">
        <AccountLinking />
      </Route>
      <Route path="/callback">
        <Auth0Callback />
      </Route>
      <Route exact path={internalPathsArray}>
        <UserDataAndLayout>
          <Route
            exact
            path={internalRootPaths.root}
            render={() => <Redirect to={internalRootPaths.organizations} />}
          />
          <Route
            path={internalRootPaths.globalTransactions}
            component={GlobalTransactionsPage}
          />
          <Route path={internalRootPaths.users} component={UsersPage} />
          <Route
            exact
            path={internalRootPaths.userDetailsPartial}
            render={(params) => (
              <Redirect
                to={`${internalRootPaths.users}${params.location.search}`}
              />
            )}
          />
          <Route path={internalRootPaths.merchants} component={MerchantsPage} />
          <Route
            path={internalRootPaths.programSettlements}
            component={ProgramSettlementsPage}
          />
          <Route
            path={internalRootPaths.settlementAccount}
            component={SettlementAccountPage}
          />
          <Route
            path={internalRootPaths.billPaymentsBatches}
            component={BillPaymentBatchesPage}
          />
          <Route
            path={internalRootPaths.cashbackPaymentBatches}
            component={CashbackPaymentBatchesPage}
          />
          <Route
            path={internalRootPaths.receivableRepayments}
            component={ReceivableRepaymentsPage}
          />
          <Route
            path={internalRootPaths.payoutBatches}
            component={PayoutBatchesPage}
          />
          <Route
            path={internalRootPaths.compensationBatches}
            component={CompensationBatchesPage}
          />
          <Route
            path={internalRootPaths.billingAccountsApprovalsAll}
            component={BillingAccountsApprovalPage}
          />
          <Route
            path={internalRootPaths.billingAccountsApprovalsReview}
            component={BillingAccountsApprovalPage}
          />
          <Route
            exact
            path={internalRootPaths.billingAccountsApproval}
            render={() => (
              <Redirect to={internalRootPaths.billingAccountsApprovalsAll} />
            )}
          />
          <Route
            exact
            path={internalRootPaths.organizations}
            component={OrganizationsPage}
          />
          <Route
            path={[
              internalRootPaths.nonCustomerOrganizations,
              internalRootPaths.organizationGroups,
            ]}
            component={OrganizationsPage}
          />
          <Route
            exact
            path={internalRootPaths.crmIntegration}
            component={CrmIntegrationPage}
          />
          <Route path={internalOrgPathsArray}>
            <OrganizationData>
              <Route
                exact
                path={internalOrgPaths.root}
                render={({ match }) => (
                  <Redirect
                    to={generatePath(internalOrgPaths.dashboard, match.params)}
                  />
                )}
              />

              <Route
                path={
                  isOrgInOnboardingNew
                    ? internalOrgPaths.orgOnboarding
                    : internalOrgPaths.onboarding
                }
                component={
                  isOrgInOnboardingNew
                    ? OrganizationOnboardingRoutes
                    : OnboardingPage
                }
              />
              <Route
                path={internalOrgPaths.dashboard}
                component={DashboardPage}
              />
              <Route
                path={internalOrgPaths.cardAccounts}
                component={CardAccountsPage}
              />
              <Route path={internalOrgPaths.cards} component={CardsPage} />
              <Route
                path={internalOrgPaths.transactions}
                component={AdminTransactionsPage}
              />
              <Route
                path={internalOrgPaths.accounting}
                component={AccountEntriesPage}
              />
              <Route
                path={internalOrgPaths.transactionsReview}
                component={TransactionsNeedsReviewPage}
              />
              <Route
                path={internalOrgPaths.transactionsFlagged}
                component={TransactionsFlaggedPage}
              />
              <Route
                path={[
                  internalOrgPaths.export,
                  internalOrgPaths.exportQueue,
                  internalOrgPaths.exported,
                ]}
                component={ExportPage}
              />
              <Route
                path={internalOrgPaths.statements}
                component={StatementsPage}
              />
              <Route
                path={internalOrgPaths.billing}
                component={BillingPageRedirect}
              />
              <Route
                path={internalOrgPaths.billingPayments}
                component={BillingPaymentsPage}
              />
              <Route
                path={internalOrgPaths.billingStatements}
                component={BillingStatementsPage}
              />
              <Route path={internalOrgPaths.members} component={MembersPage} />
              <Route path={internalOrgPaths.teams} component={TeamsPage} />
              <Route path={internalOrgPaths.rewards} component={RewardsPage} />
              <Route
                path={internalOrgPaths.partner}
                component={EmbeddedPartnerPage}
              />
              <Route
                path={internalOrgPaths.merchants}
                component={OrganizationMerchantsPage}
              />
              <Route
                path={internalOrgPaths.settlements}
                component={OrganizationSettlementsPage}
              />
              <Route
                path={internalOrgPaths.processingAccount}
                component={ProcessingAccountPage}
              />
              <Route
                exact
                path={internalOrgPaths.terms}
                render={({ match }) => (
                  <Redirect
                    to={generatePath(
                      internalOrgPaths.termsGeneral,
                      match.params
                    )}
                  />
                )}
              />
              <Route
                path={internalOrgPaths.termsGeneral}
                component={GeneralTermsPage}
              />
              <Route
                path={internalOrgPaths.termsSubscriptionSettings}
                component={SubscriptionSettingsPage}
              />
              <Route
                path={internalOrgPaths.termsCashbackSettings}
                component={CashbackSettingsPage}
              />
              <Route
                path={internalOrgPaths.pliantEarth}
                component={PliantEarthPage}
              />
              <Route path={internalOrgPaths.settings} component={Settings} />
              <Route
                path={internalOrgPaths.creditAndCompliance}
                component={CreditAndCompliance}
              />
            </OrganizationData>
          </Route>
        </UserDataAndLayout>
      </Route>
      <Route path="*">
        <AppErrorOverlay errorCode="PAGE_NOT_FOUND" />
      </Route>
    </Switch>
  );
}

export default Routes;
